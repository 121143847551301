const MatchupUl = ({
  picks,
  thisRound,
  nextRound,
  updatePick,
  correctBracket,
}) => {
  console.log(picks);
  console.log(thisRound);
  console.log(picks[thisRound]);
  return (
    <div className="centerer">
      {picks[thisRound].map((name, index) =>
        index % 2 === 0 ? (
          <ul class="matchup" key={`${nextRound}${index}`}>
            <li
              class={`team team-top t0 ${
                picks[nextRound][index / 2] === name && name !== ""
                  ? correctBracket.picks[nextRound][index / 2] === ""
                    ? "current"
                    : correctBracket.picks[nextRound][index / 2] === name
                    ? "correct"
                    : "incorrect"
                  : ""
              }`}
              onClick={() =>
                updatePick({
                  round: nextRound,
                  i: index / 2,
                  name,
                })
              }
            >
              {name}
            </li>
            <li
              class={`team team-bottom t1 ${
                picks[nextRound][index / 2] === picks[thisRound][index + 1] &&
                picks[thisRound][index + 1] !== ""
                  ? correctBracket.picks[nextRound][index / 2] === ""
                    ? "current"
                    : correctBracket.picks[nextRound][index / 2] ===
                      picks[thisRound][index + 1]
                    ? "correct"
                    : "incorrect"
                  : ""
              }`}
              onClick={() =>
                updatePick({
                  round: nextRound,
                  i: index / 2,
                  name: picks[thisRound][index + 1],
                })
              }
            >
              {picks[thisRound][index + 1]}
            </li>
          </ul>
        ) : null
      )}
    </div>
  );
};
export default MatchupUl;
