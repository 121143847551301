import "./Footer.css";

function Footer() {
  return (
    <div className="footer-container">
      <hr style={{ margin: "8px 16px" }} />
      <div className="footer">
        <div className="left">
          Provided by{" "}
          <a
            href="http://stattogether.com"
            style={{ textDecoration: "none" }}
            target="_blank"
            rel="noreferrer"
          >
            <strong>Stat Together</strong>
          </a>
        </div>
        <div className="mid">
          <a
            href="https://www.instagram.com/stattogether"
            target="_blank"
            rel="noreferrer"
          >
            <div className="social insta" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCZUaN5hIuJNQbDVFd839IVg"
            target="_blank"
            rel="noreferrer"
          >
            <div className="social youtube" />
          </a>
          <a
            href="https://www.facebook.com/StatTogetherVolleyball"
            target="_blank"
            rel="noreferrer"
          >
            <div className="social facebook" />
          </a>
        </div>
        <div className="right">
          <div>
            <a
              href="https://stattogether.com/volleyball"
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noreferrer"
            >
              <strong>Take Stats</strong>
            </a>
          </div>
          <div>
            <p>-</p>
          </div>
          <div>
            <a
              href="https://stattogether.com/streaming"
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noreferrer"
            >
              <strong>Live Stream</strong>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Footer;
