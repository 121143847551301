export class Bracket {
  constructor(id, uid, picks, champ, name, owner, group, score) {
    this.id = id;
    this.uid = uid;
    this.picks = picks;
    this.champ = champ;
    this.name = name;
    this.owner = owner;
    this.group = group;
    this.score = score;
  }
}
