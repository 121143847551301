import { BrowserRouter, Route, Routes } from "react-router-dom";

import Admin from "./Admin";
import Dashboard from "./Dashboard";
import Login from "./login/Login";
import Play from "./dashboard/Play";
import Register from "./login/Register";
import Reset from "./login/Reset";

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/reset" element={<Reset />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/aaadminview" element={<Admin />} />
          {/*New App*/}
          <Route exact path="/play" element={<Play />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;
