import "./Play.css"

import React, { useEffect, useState } from "react";
import { auth, db, wvbdb } from "../firebase";

import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router";

import {loadGame, makeNextYear} from "./objects/GameData"
import BracketView from "./components/Bracket/BracketView";

function Dashboard() {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  const [bracket, setBracket] = useState([])

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    //getCorrectBracket();
    //setBracket(loadGame(wvbdb));
  }, [user, loading]);

  return (
    <div>
        <h2>Yours</h2>
        <BracketView picks={bracket}/>
    </div>
  );
}
export default Dashboard;
