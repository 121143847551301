import "./Dashboard.css";

import React, { useEffect, useState } from "react";
import { auth, db, wvbdb } from "./firebase";

import { Bracket } from "./components/Bracket";
import { BracketView } from "./components/BracketView";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router";

const year = "2023";

const Admin = (props) => {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const [correctBracket, setCorrectBracket] = useState(null);

  const getCorrectBracket = () => {
    wvbdb
      .doc(`years/${year}`)
      .get()
      .then((doc) => {
        const data = doc.data();
        let correctB = new Bracket(
          "",
          "",
          data.correctbracket,
          data.champ,
          "",
          "",
          "",
          0
        );
        correctB.originalField = data.originalField;
        setCorrectBracket(correctB);
      })
      .catch((error) => {
        console.log("Error getting cached document:", error);
      });
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    if (user.uid != "RPID9XNdqpe9avniVezWcaYkHAU2") return navigate("/");
    getCorrectBracket();
  }, [user, loading]);

  const [editingCorrect, setEditingCorrect] = useState(false);
  const [leaderboardStatus, setLeaderboardStatus] = useState("Not Updating");

  const calcRoundScore = ({ picks, round, correctPicks }) => {
    let score = 0;
    for (let i = 0; i < picks[`l${round}`].length; i++) {
      if (
        picks[`l${round}`][i] !== "" &&
        picks[`l${round}`][i] === correctPicks[`l${round}`][i]
      ) {
        score += round === 0 ? 10 : 10 * round;
      }
      if (
        picks[`r${round}`][i] !== "" &&
        picks[`r${round}`][i] === correctPicks[`r${round}`][i]
      ) {
        score += round === 0 ? 10 : 10 * round;
      }
    }
    return score;
  };

  const calcScore = ({ picks, champ }) => {
    let totalScore = 0;
    for (let i = 0; i < 5; i++) {
      totalScore += calcRoundScore({
        picks,
        round: i,
        correctPicks: correctBracket.picks,
      });
    }
    if (champ !== "" && champ === correctBracket.champ) {
      totalScore += 50;
    }
    return totalScore;
  };

  const addBracketToArray = ({ arr, data, thisScore }) => {
    let tempArr = [...arr];
    for (let i = 0; i < arr.length; i++) {
      if (thisScore >= arr[i].score) {
        if (
          thisScore > arr[i].score ||
          (arr[i].owner !== "Andrew Adair" &&
            arr[i].owner !== "Kyle Wells" &&
            (data.owner === "Andrew Adair" ||
              data.owner === "Kyle Wells" ||
              arr[i].owner === "" ||
              (arr[i].champ.includes("1") && !data.champ.includes("1"))))
        ) {
          console.log("Pushing:");
          console.log(data);
          console.log("Above:");
          console.log(arr[i]);
          tempArr.splice(i, 0, {
            champ: data.champ,
            group: data.group,
            name: data.name,
            owner: data.owner,
            score: thisScore,
            picks: data.picks,
          });
          if (tempArr.length > 25) {
            tempArr.pop();
          }
          return tempArr;
        }
      }
    }
    tempArr.push({
      champ: data.champ,
      group: data.group,
      name: data.name,
      owner: data.owner,
      score: thisScore,
      picks: data.picks,
    });
    if (tempArr.length > 25) {
      tempArr.pop();
    }
    return tempArr;
  };

  const getMaxScore = () => {
    const maxScore = calcScore({
      picks: correctBracket.picks,
      champ: correctBracket.champ,
    });
    return maxScore;
  };

  //SET array w champ,groupname,name,score
  const updateGlobalLeaderboards = () => {
    const maxScore = getMaxScore();
    console.log(maxScore);
    setLeaderboardStatus("Connecting to DB...");
    wvbdb
      .collection(`years/${year}/brackets`)
      .get()
      .then((querySnapshot) => {
        setLeaderboardStatus("Looking at all brackets...");
        let count = 0;
        let arr = [];
        let andrewFound = false;
        let numPerfect = 0;
        querySnapshot.forEach((doc) => {
          count++;
          const data = doc.data();
          const thisScore = calcScore({ picks: data.picks, champ: data.champ });
          if (thisScore === maxScore) {
            numPerfect += 1;
            console.log("Found Perf - " + numPerfect);
          }
          if (arr.length < 25) {
            // Fill bracket
            arr = [...addBracketToArray({ arr, data, thisScore })];
          } else {
            if (thisScore >= arr[24].score) {
              if (
                thisScore > arr[24].score ||
                (data.owner !== "" &&
                  ((data.owner === "Andrew Adair" && !andrewFound) ||
                    data.owner === "Kyle Wells" ||
                    arr[24].owner === "" ||
                    (!data.champ.includes("1") && arr[24].champ.includes(1))))
              ) {
                if (data.owner === "Andrew Adair") {
                  andrewFound = true;
                }
                arr = [...addBracketToArray({ arr, data, thisScore })];
              }
            }
          }
        });
        setLeaderboardStatus("Finished Updating");
        let rank = 0;
        let prevScore = -1;
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].score !== prevScore) {
            rank++;
            prevScore = arr[i].score;
          }
          arr[i].rank = rank;
        }
        wvbdb
          .doc(`years/${year}`)
          .update({ leaderboard: arr, bracketCount: count, numPerfect })
          .then(() => {
            setLeaderboardStatus("Finished Updating");
          });
        console.log(arr);
      });
  };

  const closeBracket = () => {
    wvbdb.doc(`years/${year}`).update({ open: false });
  };

  const fixBlankNames = () => {
    setLeaderboardStatus("Connecting to DB...");
    console.log("connecting..");
    wvbdb
      .collection(`years/${year}/brackets`)
      .where("owner", "==", "")
      .get()
      .then((querySnapshot) => {
        console.log("looking..");
        setLeaderboardStatus("Looking at all blank owners...");
        querySnapshot.forEach((doc) => {
          const docid = doc.id;
          const thisData = doc.data();
          if (thisData.owner === "") {
            console.log(thisData);
            db.doc("users/" + thisData.uid)
              .get()
              .then((doc) => {
                console.log("user: " + thisData.uid);
                const userData = doc.data();
                if (userData) {
                  console.log(userData);
                  wvbdb.doc(`years/${year}/brackets` + docid).update({
                    owner: userData.firstName + " " + userData.lastName,
                  });
                }
              });
          }
        });
        setLeaderboardStatus("Finished updating names");
      });
  };

  const getIncorrectCodes = () => {
    setLeaderboardStatus("Connecting to DB...");
    wvbdb
      .collection(`years/${year}/brackets`)
      .get()
      .then((querySnapshot) => {
        setLeaderboardStatus("Looking at all brackets");
        querySnapshot.forEach((doc) => {
          const docid = doc.id;
          const thisData = doc.data();
          if (thisData.group.length != 5) {
            console.log(thisData);
            wvbdb.doc(`years/${year}/brackets/` + docid).delete();
          }
        });
        setLeaderboardStatus("Finished getting bad brackets");
      });
  };

  const getStats = () => {
    setLeaderboardStatus("Connecting to DB...");
    let popularChamp = {};
    let firstRoundUpset = {};
    wvbdb
      .collection(`years/${year}/brackets`)
      .get()
      .then((querySnapshot) => {
        setLeaderboardStatus("Looking at all brackets");
        querySnapshot.forEach((doc) => {
          const docid = doc.id;
          const thisData = doc.data();
          // Track championship picks
          if (popularChamp[thisData.champ]) {
            popularChamp[thisData.champ] += 1;
          } else {
            popularChamp[thisData.champ] = 1;
          }
          // Track first round upsets
          const firstRounds = [thisData.picks.l0, thisData.picks.r0];
          for (var round of firstRounds) {
            //Check both first round sides
            for (var pick of round) {
              //Iterate over each pick
              if (pick[1] != " ") {
                //Upsets in the first round means a pick isn't seeded.
                //Seeded picks will have a space like "1 Louisville"
                if (firstRoundUpset[pick]) {
                  firstRoundUpset[pick] += 1;
                } else {
                  firstRoundUpset[pick] = 1;
                }
              }
            }
          }
        });

        wvbdb.doc(`years/${year}`).update({
          popularChamp: popularChamp,
          firstRoundUpset: firstRoundUpset,
        });
        setLeaderboardStatus("Finished getting stats");
      });
  };

  if (user && user.uid === "RPID9XNdqpe9avniVezWcaYkHAU2") {
    return (
      <div>
        {!editingCorrect ? (
          <div>
            <h1>Admin</h1>
            {correctBracket ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <button onClick={() => setEditingCorrect(true)}>
                  Update Correct Bracket
                </button>
                <button onClick={() => updateGlobalLeaderboards()}>
                  Update Global Leaderboad
                </button>
                <h5>Status: {leaderboardStatus}</h5>
                <button onClick={() => fixBlankNames()}>Fix Owners</button>
                <button onClick={() => closeBracket()}>Close Bracket</button>
                <button onClick={() => getIncorrectCodes()}>
                  Print bad codes
                </button>
                <button onClick={() => getStats()}>Get Bracket Stats</button>
              </div>
            ) : null}
          </div>
        ) : correctBracket ? (
          <BracketView
            setEditingCorrect={setEditingCorrect}
            setCorrectBracket={setCorrectBracket}
            correctBracket={correctBracket}
            editable={true}
          />
        ) : null}
      </div>
    );
  } else {
    return <h1>dead link</h1>;
  }
};

export default Admin;
