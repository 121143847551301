import "./GroupView.css";

import React, { useState } from "react";

export const GroupView = ({
  group,
  setThisBracket,
  setEditing,
  setSelectedGroup,
}) => {
  return (
    <div className="groupView">
      <h1>{group.name}</h1>
      <table>
        <thead>
          <th>Name</th>
          <th>Owner</th>
          <th>Score</th>
          <th>Champion</th>
        </thead>
        <tbody>
          {group.brackets
            .sort((a, b) => (a.score < b.score ? 1 : -1))
            .map((bracket, index) => (
              <tr key={index}>
                <td
                  style={{
                    color: "rgb(131, 156, 214)",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() => {
                    setThisBracket(bracket);
                    setEditing(true);
                  }}
                >
                  {bracket.name}
                </td>
                <td>{bracket.owner}</td>
                <td>{bracket.score}</td>
                <td>{bracket.champ}</td>
              </tr>
            ))}
        </tbody>
      </table>
      <button className="closebutton" onClick={() => setSelectedGroup("")}>
        Close
      </button>
    </div>
  );
};
