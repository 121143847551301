import "./BracketView.css"

function Matchup({team1, team2, winner, index}){
    return(
        <div className="matchup" key={"matchup"+index}>
            <div className={winner == team1 ? "team selected":"team"}>{team1}</div>
            <div className={winner == team2 ? "team selected":"team"}>{team2}</div>
        </div>
    )
}

function Round({thisRound, nextRound, index}){
    return(
        <div className="round" key={"round"+index}>
            {thisRound.map((team1,index)=>{
                if(index % 2 == 1 || thisRound[index+1] == null) return null
                return (
                    <Matchup team1={team1} team2={thisRound[index+1]}
                    winner={nextRound ? nextRound[index/2] : null} index={index} />
                )
            })}
        </div>
    )
}

function Quadrant({thisQuad, index}){
    for(let i=0;i<15;i++){
        if(i%2 == 0){
            //1st Round Matchup
            console.log(thisQuad[0][i])
            console.log(thisQuad[0][i+1])
        }else if(i==7){
            //4th Championship
            console.log("\t\t\t\t"+thisQuad[3][0])
            console.log("\t\t\t\t"+thisQuad[3][1])
        }else if((i+1)%4 == 0){
            //3rd Round
            console.log("\t\t\t"+thisQuad[2][(i+1)/4-1])
            console.log("\t\t\t"+thisQuad[2][(i+1)/4])
        }else{
            //2nd Round
            console.log("\t\t"+thisQuad[1][(i-1)/2])
            console.log("\t\t"+thisQuad[1][(i-1)/2+1])
        }
    }
    return(
        <div className="quadrant" key={"quad"+index}>
            {thisQuad.map((round,index)=>{
                return (
                    <Round thisRound={round} nextRound={index==4?null:thisQuad[index+1]} index={index}/>
                )
            })}
        </div>
      );
}

function QuadrantButtons({thisQuad}){

    return(
        <div className="qBtns">
            <div className={thisQuad[1].includes("") ? "roundBtn" : "roundBtn complete"}>Round 1</div>
            <div className={thisQuad[2].includes("") ? "roundBtn" : "roundBtn complete"}>Round 2</div>
            <div className={thisQuad[3].includes("") ? "roundBtn" : "roundBtn complete"}>Round 3</div>
            <div className={thisQuad[4].includes("") ? "roundBtn" : "roundBtn complete"}>Round 4</div>
        </div>
    )
}

function BracketView() {
    const picks = {
        "topRight":[
            ["Stanford","Pepperdine","LSU","Hawaii","Houston","South Dakota",
            "Auburn","Creighton","Kentucky","Loyola Chicago","Bowling Green","Western Kentucky",
            "Washington State","UNLV","Northern Colorado","San Diego"],
            ["Stanford","LSU","Houston","Auburn","Kentucky","Bowling Green",
            "Washington State","Northern Colorado"],
            ["Stanford","Auburn","Bowling Green","Northern Colorado"],
            ["Stanford","Bowling Green"],
            ["Stanford"]
        ]
    }
    return(
        <div className="bracket">
            {/* <h2>Top Left</h2> */}
            {/* <QuadrantButtons thisQuad={picks.topRight}/> */}
            <Quadrant thisQuad={picks.topRight} index={0}/>
        </div>
    );
}
export default BracketView;