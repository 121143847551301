import firebase from "firebase";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB_oQFe6_yYdOcqj95XT0Zt2Ay94S3DN9U",
  authDomain: "volley-stats.firebaseapp.com",
  databaseURL: "https://volley-stats.firebaseio.com",
  projectId: "volley-stats",
  storageBucket: "volley-stats.appspot.com",
  messagingSenderId: "746174909898",
  appId: "1:746174909898:web:d5e3c4626372677bf81333",
  measurementId: "G-SLR7YKSSG1",
};

const wvbfirebaseConfig = {
  apiKey: "AIzaSyCzZQoc-zU-003_rSR5DlEYC65bhwQbHBQ",
  authDomain: "wvb-bracket.firebaseapp.com",
  projectId: "wvb-bracket",
  storageBucket: "wvb-bracket.appspot.com",
  messagingSenderId: "766103906408",
  appId: "1:766103906408:web:caefc6eb8af995411ab3dd",
  measurementId: "G-CS24VQH63V",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const auth = app.auth();
const db = app.firestore();
const wvbapp = firebase.initializeApp(wvbfirebaseConfig, "secondary");
const wvbdb = wvbapp.firestore();

const signInWithEmailAndPassword = async (email, password) => {
  try {
    await auth.signInWithEmailAndPassword(email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const registerWithEmailAndPassword = async (fname, lname, email, password) => {
  try {
    const res = await auth.createUserWithEmailAndPassword(email, password);
    const user = res.user;
    await db.doc("users/" + user.uid).set({
      firstName: fname,
      lastName: lname,
      email,
      joinSource: "bracket",
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const sendPasswordResetEmail = async (email) => {
  try {
    await auth.sendPasswordResetEmail(email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const logout = () => {
  auth.signOut();
};
export {
  auth,
  db,
  wvbdb,
  signInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordResetEmail,
  logout,
};
