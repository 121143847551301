import "./BracketView.css";

import React, { useState } from "react";

import MatchupUl from "./MatchupUl";
import Popup from "./Popup";
import firebase from "firebase";
import { wvbdb } from "../firebase";

const year = 2023;

const emptyNames = {
  l0: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
  r0: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
};

export const thesePicksEmpty = {
  l0: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
  l1: ["", "", "", "", "", "", "", ""],
  l2: ["", "", "", ""],
  l3: ["", ""],
  l4: [""],
  r0: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
  r1: ["", "", "", "", "", "", "", ""],
  r2: ["", "", "", ""],
  r3: ["", ""],
  r4: [""],
};

export const BracketView = ({
  correctBracket,
  thisBracket,
  editable,
  setEditing,
  groupCode,
  uid,
  getUserData,
  owner,
  setEditingCorrect,
  setCorrectBracket,
}) => {
  console.log("Correct:");
  console.log(correctBracket);
  const field = correctBracket
    ? JSON.parse(JSON.stringify(correctBracket.originalField))
    : emptyNames;

  const [thesePicks, setThesePicks] = useState(
    thisBracket
      ? JSON.parse(JSON.stringify(thisBracket.picks))
      : setEditingCorrect
      ? JSON.parse(JSON.stringify(correctBracket.picks))
      : JSON.parse(JSON.stringify(thesePicksEmpty))
  );
  const [thisChamp, setThisChamp] = useState(
    thisBracket ? thisBracket.champ : ""
  );
  const [thisName, setThisName] = useState(thisBracket ? thisBracket.name : "");
  const [errorMessage, setErrorMessage] = useState("");

  const updatePick = ({ round, i, name }) => {
    if (editable) {
      const original = thesePicks[round][i];
      if (name === original) {
        return;
      }
      if (thisChamp === original) {
        setThisChamp("");
      }

      let tempPicks = { ...thesePicks };
      tempPicks[round][i] = name;

      //Empty Future
      const side = round.charAt(0);
      const roundNum = parseInt(round.charAt(1));
      if (original !== name) {
        for (const [key, value] of Object.entries(thesePicks)) {
          if (parseInt(key.charAt(1)) >= roundNum) {
            for (let entry in tempPicks[key]) {
              if (tempPicks[key][entry] === original) {
                tempPicks[key][entry] = "";
              }
            }
          }
        }
      }

      //SET
      setThesePicks(tempPicks);
    }
  };

  const [savingBracket, setSavingBracket] = useState(false);
  const saveBracket = () => {
    if (setEditing == null && uid == null) {
      console.log("Editing correct bracket");
      wvbdb
        .doc(`years/${year}`)
        .update({ correctbracket: thesePicks, champ: thisChamp })
        .then(() => {
          setEditingCorrect(false);
          let tempCBracket = JSON.parse(JSON.stringify(correctBracket));
          tempCBracket.picks = thesePicks;
          setCorrectBracket(tempCBracket);
        });
    } else if (!correctBracket.open) {
      setErrorMessage(
        "The tournament has started! You can no longer create new brackets or edit existing ones."
      );
    } else if (thisName === "") {
      setErrorMessage("You need to give your bracket a name!");
    } else if (thisChamp === "") {
      setErrorMessage(
        "You need to fill out your entire bracket - pick a champion!"
      );
    } else {
      let foundError = false;
      for (const [key, value] of Object.entries(thesePicks)) {
        for (let entry in thesePicks[key]) {
          if (thesePicks[key][entry] === "") {
            foundError = true;
            setErrorMessage("You need to fill out your entire bracket!");
          }
        }
      }
      if (!foundError) {
        setSavingBracket(true);
        if (thisBracket) {
          console.log("Editing bracket");
          wvbdb
            .doc(`years/${year}/brackets/${thisBracket.id}`)
            .update({
              champ: thisChamp,
              name: thisName,
              picks: thesePicks,
            })
            .then(() => {
              getUserData({ correctB: correctBracket });
              setEditing(false);
              setSavingBracket(false);
            })
            .catch((error) => {
              setSavingBracket(false);
              console.log(error);
            });
        } else {
          wvbdb
            .collection(`years/${year}/brackets/`)
            .add({
              champ: thisChamp,
              group: groupCode,
              name: thisName,
              picks: thesePicks,
              uid,
              owner,
            })
            .then(() => {
              getUserData({ correctB: correctBracket });
              setEditing(false);
              setSavingBracket(false);
              wvbdb.doc(`years/${year}`).update({
                bracketCount: firebase.firestore.FieldValue.increment(1),
              });
            })
            .catch((error) => {
              setSavingBracket(false);
              console.log("error");
            });
        }
      } else {
        setErrorMessage("You need to fill out your entire bracket");
      }
    }
  };

  return (
    <div style={{ textAlign: "center" }}>
      {editable ? null : <h1 style={{ color: "white" }}>{thisName}</h1>}
      <div className={`bracketView ${editable ? "" : "nohover"}`}>
        <div class="split split-one" id="left">
          <div className="inner-split">
            <div class="round round-zero r0">
              <div class="round-details">
                Round 1 (10)
                <br />
                <span class="date">Nov 30 - Dec 1</span>
              </div>
              {field.l0.map((name, index) =>
                index % 2 === 0 ? (
                  <ul class="matchup" key={"l0" + index}>
                    <li
                      class={`team team-top t0 ${
                        thesePicks.l0[index / 2] === name && name !== ""
                          ? correctBracket.picks.l0[index / 2] === ""
                            ? "current"
                            : correctBracket.picks.l0[index / 2] === name
                            ? "correct"
                            : "incorrect"
                          : ""
                      }`}
                      onClick={() =>
                        updatePick({ round: "l0", i: index / 2, name })
                      }
                    >
                      {name}
                    </li>
                    <li
                      class={`team team-bottom t1 ${
                        thesePicks.l0[index / 2] === field.l0[index + 1] &&
                        field.l0[index + 1] !== ""
                          ? correctBracket.picks.l0[index / 2] === ""
                            ? "current"
                            : correctBracket.picks.l0[index / 2] ===
                              field.l0[index + 1]
                            ? "correct"
                            : "incorrect"
                          : ""
                      } `}
                      onClick={() =>
                        updatePick({
                          round: "l0",
                          i: index / 2,
                          name: field.l0[index + 1],
                        })
                      }
                    >
                      {field.l0[index + 1]}
                    </li>
                  </ul>
                ) : null
              )}
            </div>
            <div class="round round-one r1">
              <div class="round-details">
                Round 2 (10)
                <br />
                <span class="date">December 1-2</span>
              </div>
              <MatchupUl
                picks={thesePicks}
                thisRound={"l0"}
                nextRound={"l1"}
                updatePick={updatePick}
                correctBracket={correctBracket}
              />
            </div>
          </div>
          <div className="inner-split">
            <div class="round round-two r2">
              <div class="round-details">
                Round 3 (20)
                <br />
                <span class="date">December 7-9</span>
              </div>
              <MatchupUl
                picks={thesePicks}
                thisRound={"l1"}
                nextRound={"l2"}
                updatePick={updatePick}
                correctBracket={correctBracket}
              />
            </div>
            <div class="round round-three r3">
              <div class="round-details">
                Round 4 (30)
                <br />
                <span class="date">December 14</span>
              </div>
              <MatchupUl
                picks={thesePicks}
                thisRound={"l2"}
                nextRound={"l3"}
                updatePick={updatePick}
                correctBracket={correctBracket}
              />
            </div>
          </div>
        </div>
        <div class="split split-two" id="right">
          <div className="inner-split">
            <div class="round round-three r3">
              <div class="round-details">
                Round 4 (30)
                <br />
                <span class="date">December 17</span>
              </div>
              <MatchupUl
                picks={thesePicks}
                thisRound={"r2"}
                nextRound={"r3"}
                updatePick={updatePick}
                correctBracket={correctBracket}
              />
            </div>
            <div class="round round-two r2">
              <div class="round-details">
                Round 3 (20)
                <br />
                <span class="date">December 7-9</span>
              </div>
              <MatchupUl
                picks={thesePicks}
                thisRound={"r1"}
                nextRound={"r2"}
                updatePick={updatePick}
                correctBracket={correctBracket}
              />
            </div>
          </div>
          <div className="inner-split">
            <div class="round round-one r1">
              <div class="round-details">
                Round 2 (10)
                <br />
                <span class="date">December 1-2</span>
              </div>
              <MatchupUl
                picks={thesePicks}
                thisRound={"r0"}
                nextRound={"r1"}
                updatePick={updatePick}
                correctBracket={correctBracket}
              />
            </div>
            <div class="round round-zero r0">
              <div class="round-details">
                Round 1 (10)
                <br />
                <span class="date">Nov 30-Dec 1</span>
              </div>
              {field.r0.map((name, index) =>
                index % 2 === 0 ? (
                  <ul class="matchup" key={`r0+${index}`}>
                    <li
                      class={`team team-top t0 ${
                        thesePicks.r0[index / 2] === name && name !== ""
                          ? correctBracket.picks.r0[index / 2] === ""
                            ? "current"
                            : correctBracket.picks.r0[index / 2] === name
                            ? "correct"
                            : "incorrect"
                          : ""
                      }`}
                      onClick={() =>
                        updatePick({ round: "r0", i: index / 2, name })
                      }
                    >
                      {name}
                    </li>
                    <li
                      class={`team team-bottom t1 ${
                        thesePicks.r0[index / 2] === field.r0[index + 1] &&
                        field.r0[index + 1] !== ""
                          ? correctBracket.picks.r0[index / 2] === ""
                            ? "current"
                            : correctBracket.picks.r0[index / 2] ===
                              field.r0[index + 1]
                            ? "correct"
                            : "incorrect"
                          : ""
                      }`}
                      onClick={() =>
                        updatePick({
                          round: "r0",
                          i: index / 2,
                          name: field.r0[index + 1],
                        })
                      }
                    >
                      {field.r0[index + 1]}
                    </li>
                  </ul>
                ) : null
              )}
            </div>
          </div>
        </div>

        {errorMessage !== "" && (
          <Popup
            content={
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <b>Error</b>
                <p>{errorMessage}</p>
              </div>
            }
            handleClose={() => setErrorMessage("")}
          />
        )}
      </div>
      <div class="champion">
        <div class="semis-l r4">
          <div class="round-details">
            semifinals (40)
            <br />
            <span class="date">December 15</span>
          </div>
          <ul class="matchup">
            <li
              class={`team team-top t0  ${
                thesePicks.l4[0] === thesePicks.l3[0] && thesePicks.l3[0] !== ""
                  ? correctBracket.picks.l4[0] === ""
                    ? "current"
                    : correctBracket.picks.l4[0] === thesePicks.l3[0]
                    ? "correct"
                    : "incorrect"
                  : ""
              }`}
              onClick={() =>
                updatePick({
                  round: "l4",
                  i: 0,
                  name: thesePicks.l3[0],
                })
              }
            >
              {thesePicks.l3[0]}
            </li>
            <li
              class={`team team-bottom t1  ${
                thesePicks.l4[0] === thesePicks.r3[0] && thesePicks.r3[0] !== ""
                  ? correctBracket.picks.l4[0] === ""
                    ? "current"
                    : correctBracket.picks.l4[0] === thesePicks.r3[0]
                    ? "correct"
                    : "incorrect"
                  : ""
              }`}
              onClick={() =>
                updatePick({
                  round: "l4",
                  i: 0,
                  name: thesePicks.r3[0],
                })
              }
            >
              {thesePicks.r3[0]}
            </li>
          </ul>
        </div>
        <div class="final r5">
          <i class="fa fa-trophy"></i>
          <div class="round-details">
            championship (50)
            <br />
            <span class="date">December 17</span>
          </div>
          <ul class="matchup">
            <li
              class={`team team-top t0  ${
                thesePicks.l4[0] === thisChamp && thisChamp !== ""
                  ? correctBracket.picks.l4[0] === ""
                    ? "current"
                    : correctBracket.picks.l4[0] === thisChamp
                    ? "correct"
                    : "incorrect"
                  : ""
              }`}
              onClick={() => {
                if (editable) {
                  setThisChamp(thesePicks.l4[0]);
                }
              }}
            >
              {thesePicks.l4[0]}
            </li>
            <li
              class={`team team-bottom t1 ${
                thesePicks.r4[0] === thisChamp && thisChamp !== ""
                  ? correctBracket.picks.r4[0] === ""
                    ? "current"
                    : correctBracket.picks.r4[0] === thisChamp
                    ? "correct"
                    : "incorrect"
                  : ""
              }`}
              onClick={() => {
                if (editable) {
                  setThisChamp(thesePicks.r4[0]);
                }
              }}
            >
              {thesePicks.r4[0]}
            </li>
          </ul>
        </div>
        <div class="semis-r r4">
          <div class="round-details">
            semifinals (40)
            <br />
            <span class="date">December 15</span>
          </div>
          <ul class="matchup">
            <li
              class={`team team-top t0  ${
                thesePicks.r4[0] === thesePicks.l3[1] && thesePicks.l3[1] !== ""
                  ? correctBracket.picks.r4[0] === ""
                    ? "current"
                    : correctBracket.picks.r4[0] === thesePicks.l3[1]
                    ? "correct"
                    : "incorrect"
                  : ""
              }`}
              onClick={() =>
                updatePick({
                  round: "r4",
                  i: 0,
                  name: thesePicks.l3[1],
                })
              }
            >
              {thesePicks.l3[1]}
            </li>
            <li
              class={`team team-bottom t1  ${
                thesePicks.r4[0] === thesePicks.r3[1] && thesePicks.r3[1] !== ""
                  ? correctBracket.picks.r4[0] === ""
                    ? "current"
                    : correctBracket.picks.r4[0] === thesePicks.r3[1]
                    ? "correct"
                    : "incorrect"
                  : ""
              }`}
              onClick={() =>
                updatePick({
                  round: "r4",
                  i: 0,
                  name: thesePicks.r3[1],
                })
              }
            >
              {thesePicks.r3[1]}
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div
          style={{
            display: "block",
            textAlign: "center",
          }}
        >
          {editable && setEditingCorrect == null ? (
            <div>
              <input
                type="text"
                placeholder='Name ("Awesome Bracket")'
                value={thisName}
                onChange={(e) => setThisName(e.target.value)}
              />
            </div>
          ) : null}
        </div>
        <div className="submitContainer">
          <button
            onClick={() => {
              if (setEditingCorrect == null) {
                setEditing(false);
              } else {
                setEditingCorrect(false);
              }
            }}
            disabled={savingBracket}
            id="cancel"
          >
            {editable ? "Cancel" : "Close"}
          </button>
          {editable ? (
            <button onClick={() => saveBracket()} disabled={savingBracket}>
              {savingBracket ? "Saving" : "Save Bracket"}
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};
