import "./LoggedHeader.css";

import headerImg from "../assets/headerimg.png";
import { logout } from "../firebase";

function LoggedHeader() {
  return (
    <div className="loggedheader">
      <div className="left">
        <a
          href="http://stattogether.com"
          style={{ textDecoration: "none" }}
          target="_blank"
          rel="noreferrer"
        >
          <strong>Stat Together</strong>
        </a>{" "}
        Bracket Challenge
      </div>
      <div className="right">
        <button className="header_bttn" onClick={logout}>
          Logout
        </button>
      </div>
    </div>
  );
}
export default LoggedHeader;
