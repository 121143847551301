import "./Register.css";

import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { auth, registerWithEmailAndPassword } from "../firebase";

import Header from "../components/Header";
import { useAuthState } from "react-firebase-hooks/auth";

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setcPassword] = useState("");
  const [fname, setfName] = useState("");
  const [lname, setlName] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const register = () => {
    if (!fname) alert("Please enter first name");
    else if (!lname) alert("Please enter last name");
    else if (password != cpassword) alert("Passwords don't match");
    else {
      registerWithEmailAndPassword(fname, lname, email, password);
    }
  };
  useEffect(() => {
    if (loading) return;
    if (user) navigate("/dashboard");
  }, [user, loading]);
  return (
    <div class="content">
      <Header />
      <div className="register">
        <div className="register__container">
          <div className="input_container">
            <input
              type="text"
              className="register__textBox"
              value={fname}
              onChange={(e) => setfName(e.target.value)}
              placeholder="First Name"
            />
            <input
              type="text"
              className="register__textBox"
              value={lname}
              onChange={(e) => setlName(e.target.value)}
              placeholder="Last Name"
            />
          </div>
          <div className="input_container">
            <input
              type="text"
              className="register__textBox"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="E-mail Address"
            />
          </div>
          <div className="input_container">
            <input
              type="password"
              className="register__textBox"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />
            <input
              type="password"
              className="register__textBox"
              value={cpassword}
              onChange={(e) => setcPassword(e.target.value)}
              placeholder="Confirm Password"
            />
          </div>
          <p>
            By signing up, you will start receiving updates about the Stat
            Together app.{" "}
            <a
              href="https://stattogether.com/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </p>
          <button className="register__btn" onClick={register}>
            Register
          </button>
          <div>
            Already have an account? <Link to="/">Login</Link> now.
          </div>
        </div>
      </div>
    </div>
  );
}
export default Register;
