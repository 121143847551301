import "./Dashboard.css";

import React, { useEffect, useState } from "react";
import { auth, db, wvbdb } from "./firebase";

import { Bracket } from "./components/Bracket";
import { BracketView } from "./components/BracketView";
import Chart from "react-google-charts";
import Footer from "./Footer";
import { Group } from "./components/Group";
import { GroupView } from "./components/GroupView";
import LoggedHeader from "./components/LoggedHeader";
import Popup from "./components/Popup";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router";

function Dashboard() {
  const [user, loading, error] = useAuthState(auth);
  const [myName, setMyName] = useState("");
  const [myBrackets, setMyBrackets] = useState([]);
  const [myGroups, setMyGroups] = useState({});

  const [correctBracket, setCorrectBracket] = useState(null);

  const [groupName, setGroupName] = useState("");
  const [groupCode, setGroupCode] = useState("");
  const [findError, setFindError] = useState("");
  const [searchingForGroup, setSearchingForGroup] = useState("");

  const [selectedGroup, setSelectedGroup] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isCGOpen, setIsCGOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [thisBracket, setThisBracket] = useState(null);

  const [editing, setEditing] = useState(false);

  const navigate = useNavigate();

  const year = 2023;

  const calcRoundScore = ({ picks, round, correctPicks }) => {
    let score = 0;
    for (let i = 0; i < picks[`l${round}`].length; i++) {
      if (picks[`l${round}`][i] === correctPicks[`l${round}`][i]) {
        score += round === 0 ? 10 : 10 * round;
      }
      if (picks[`r${round}`][i] === correctPicks[`r${round}`][i]) {
        score += round === 0 ? 10 : 10 * round;
      }
    }
    return score;
  };

  const calcScore = ({ picks, champ, correctB }) => {
    let totalScore = 0;
    for (let i = 0; i < 5; i++) {
      totalScore += calcRoundScore({
        picks,
        round: i,
        correctPicks: correctB.picks,
      });
    }
    if (champ === correctB.champ) {
      totalScore += 50;
    }
    return totalScore;
  };

  const getOpponent = (originalField, firstRoundPick) => {
    const sides = [originalField.l0, originalField.r0];
    let i = 0;
    for (var side of sides) {
      for (var pick of side) {
        if (pick == firstRoundPick) {
          if (i % 2 == 0) {
            return side[i + 1];
          } else {
            return side[i - 1];
          }
        }
        i++;
      }
      i = 0;
    }
  };

  const getCorrectBracket = () => {
    wvbdb
      .doc(`years/${year}`)
      .get()
      .then((doc) => {
        const data = doc.data();
        let correctB = new Bracket(
          "",
          "",
          data.correctbracket,
          data.champ,
          "",
          "",
          "",
          0
        );
        correctB.originalField = data.originalField;
        correctB.leaderboard = data.leaderboard;
        correctB.bracketCount = data.bracketCount;
        correctB.open = data.open;
        correctB.numPerfect = data.numPerfect;
        //SORT CHAMPION DATA
        const popularChamp = data.popularChamp;
        let sortableChamp = [["Pick", "Votes"]];
        for (var champ in popularChamp) {
          const percent = popularChamp[champ] / correctB.bracketCount;
          if (percent > 0.005) {
            let name = champ;
            if (champ[1] == " ") {
              name = champ.substring(2);
            }
            sortableChamp.push([name, popularChamp[champ]]);
          }
        }
        sortableChamp.sort(function (a, b) {
          return -(a[1] - b[1]);
        });
        correctB.sortableChamp = [...sortableChamp];
        //SORT FIRST ROUND UPSETS
        const firstRoundUpset = data.firstRoundUpset;
        let sortableFRU = [];
        for (var pick in firstRoundUpset) {
          const percent = firstRoundUpset[pick] / correctB.bracketCount;
          if (percent > 0.1) {
            sortableFRU.push([
              pick,
              firstRoundUpset[pick],
              getOpponent(data.originalField, pick),
            ]);
          }
        }
        sortableFRU.sort(function (a, b) {
          return -(a[1] - b[1]);
        });
        correctB.sortableFRU = [...sortableFRU];

        setCorrectBracket(correctB);
        getUserData({ correctB: correctB });
      })
      .catch((error) => {
        console.log("Error getting cached document:", error);
      });
  };

  const getAllGroups = async ({ groupIds, index, newObject, correctB }) => {
    if (index < groupIds.length) {
      wvbdb
        .doc(`years/${year}/groups/${groupIds[index]}`)
        .get()
        .then((doc) => {
          const data = doc.data();
          const newGroup = new Group(
            data.name,
            data.owner,
            [],
            groupIds[index]
          );
          newObject[groupIds[index]] = newGroup;
          wvbdb
            .collection(`years/${year}/brackets`)
            .where("group", "==", groupIds[index])
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                const data = doc.data();
                let newB = new Bracket(
                  doc.id,
                  data.uid,
                  data.picks,
                  data.champ,
                  data.name,
                  data.owner,
                  data.group,
                  calcScore({
                    picks: data.picks,
                    champ: data.champ,
                    correctB,
                  })
                );
                newObject[groupIds[index]].brackets.push(newB);
              });
              getAllGroups({ groupIds, index: index + 1, newObject, correctB });
            });
        })
        .catch((error) => {
          console.log("Error getting cached document:", error);
        });
    } else {
      for (const [key, group] of Object.entries(newObject)) {
        group.highscore = -1;
        group.numberOfBest = 1;
        for (const thisBracket of group.brackets) {
          if (thisBracket.score > group.highscore) {
            group.highscore = thisBracket.score;
            group.bestBracket = thisBracket;
            group.numberOfBest = 1;
          } else if (thisBracket.score === group.highscore) {
            group.numberOfBest += 1;
          }
          if (thisBracket.uid === user.uid) {
            group.myScore = thisBracket.score;
          }
        }
      }
      setMyGroups(newObject);
    }
  };

  const getUserData = ({ correctB }) => {
    db.doc("users/" + user.uid)
      .get()
      .then((doc) => {
        const data = doc.data();
        setMyName(data.firstName + " " + data.lastName);
      })
      .catch((error) => {
        console.log("Error getting cached user document:", error);
      });

    let groupIds = [];
    wvbdb
      .collection(`years/${year}/brackets`)
      .where("uid", "==", user.uid)
      .get()
      .then((querySnapshot) => {
        let newBrackets = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          let newB = new Bracket(
            doc.id,
            data.uid,
            data.picks,
            data.champ,
            data.name,
            data.owner,
            data.group,
            calcScore({
              picks: data.picks,
              champ: data.champ,
              correctB,
            })
          );
          newBrackets.push(newB);
          if (!groupIds.includes(data.group)) {
            groupIds.push(data.group);
          }
        });
        getAllGroups({ groupIds, index: 0, newObject: {}, correctB });
        setMyBrackets(newBrackets);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  };

  //MARK: UI STUFF
  const searchForGroup = () => {
    console.log(`Searching for years/${year}/groups/${groupCode}`);
    if (myGroups[groupCode]) {
      console.log("Already in");
      setFindError(
        "You've already made a bracket for that group!  You can edit your brackets from the main page."
      );
    } else {
      setSearchingForGroup(true);
      wvbdb
        .doc(`years/${year}/groups/${groupCode}`)
        .get()
        .then((doc) => {
          console.log("Found!");
          console.log(doc.data());
          if (doc.data()) {
            setSearchingForGroup(false);
            setFindError(false);
            const data = doc.data();
            setThisBracket(null);
            setEditing(true);
            setIsOpen(false);
          } else {
            console.log("Not found...");
            setSearchingForGroup(false);
            setFindError("Could not find that group code!");
          }
        })
        .catch((error) => {
          console.log("Not found...");
          setSearchingForGroup(false);
          setFindError("Could not find that group code!");
        });
    }
  };

  const createCode = () => {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 5; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const createGroup = () => {
    const code = createCode();
    console.log("Creating:" + code);
    setSearchingForGroup(true);
    wvbdb
      .doc(`years/${year}/groups/` + code)
      .get()
      .then((doc) => {
        setSearchingForGroup(false);
        setFindError(false);
        const data = doc.data();
        if (data === undefined) {
          throw ErrorEvent;
        }
      })
      .catch((error) => {
        wvbdb
          .doc(`years/${year}/groups/` + code)
          .set({ name: groupName, owner: user.uid })
          .then((doc) => {
            console.log("Made it");
            setGroupCode(code.trim());
            setThisBracket(null);
            setEditing(true);
            setIsCGOpen(false);
            setSearchingForGroup(false);
          })
          .catch((error) => {
            console.log(error);
            setSearchingForGroup(false);
          });
      });
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    getCorrectBracket();
  }, [user, loading]);

  return (
    <div
      class="content"
      style={selectedGroup ? { height: "90vh", overflow: "hidden" } : {}}
    >
      {correctBracket ? (
        editing ? (
          <BracketView
            correctBracket={correctBracket}
            thisBracket={thisBracket}
            editable={
              thisBracket
                ? correctBracket.open && thisBracket.uid === user.uid
                : true
            }
            setEditing={setEditing}
            groupCode={thisBracket ? thisBracket.group : groupCode}
            uid={user.uid}
            getUserData={getUserData}
            owner={myName}
          />
        ) : (
          <div>
            <LoggedHeader />
            <div className="dashboard">
              {correctBracket.open && myBrackets.length === 0 ? (
                <div style={{ padding: "16px" }}>
                  <h1>Welcome to the {year} Bracket Challenge!</h1>
                  <p>
                    To get started with your first bracket, either create a new
                    group where you and your friends can compete against each
                    other or join an existing group!
                  </p>
                </div>
              ) : (
                <>
                  <h1>Your Brackets</h1>
                  <h5>
                    Tap a bracket name to view
                    {correctBracket.open ? " or edit" : ""}
                  </h5>
                  <div className="inner-card">
                    <table>
                      <thead>
                        <th>Name</th>
                        <th>Score</th>
                        <th>Champion</th>
                        <th>Group</th>
                        {/* <th></th> */}
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <tr>
                            <td colSpan="4">Loading...</td>
                          </tr>
                        ) : (
                          myBrackets.map((bracket, index) => (
                            <tr key={index}>
                              <td
                                style={{
                                  color: "rgb(131, 156, 214)",
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                }}
                                onClick={() => {
                                  setThisBracket(bracket);
                                  setEditing(true);
                                }}
                              >
                                {bracket.name}
                              </td>
                              <td>{bracket.score}</td>
                              <td>{bracket.champ}</td>
                              <td>
                                {myGroups[bracket.group]
                                  ? myGroups[bracket.group].name
                                  : ""}
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
              {isLoading || !correctBracket.open ? null : (
                <button
                  onClick={() => {
                    setGroupCode("");
                    setIsOpen(true);
                  }}
                >
                  Join a Group
                </button>
              )}
              {myBrackets.length === 0 ? null : (
                <>
                  <h1>Your Groups</h1>
                  <h5>Tap a group name to view all the brackets</h5>
                  <div className="inner-card">
                    <table>
                      <thead>
                        <th>Group Name</th>
                        <th>Group Code</th>
                        <th>Best Bracket</th>
                        <th>Best Score</th>
                        <th>Your Score</th>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <tr>
                            <td colSpan="5">Loading...</td>
                          </tr>
                        ) : (
                          Object.values(myGroups).map((group, index) => (
                            <tr key={index}>
                              <td
                                style={{
                                  color: "rgb(131, 156, 214)",
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                }}
                                onClick={() => setSelectedGroup(group.id)}
                              >
                                {group.name}
                              </td>
                              <td>{group.id}</td>
                              {group.numberOfBest === 1 ? (
                                <td
                                  style={{
                                    color: "rgb(131, 156, 214)",
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                  }}
                                  onClick={() => {
                                    setThisBracket(group.bestBracket);
                                    setEditing(true);
                                  }}
                                >
                                  {group.bestBracket.name}
                                </td>
                              ) : (
                                <td>{`${group.numberOfBest} Tied`}</td>
                              )}
                              <td>{group.highscore}</td>
                              <td>{group.myScore}</td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
              {isLoading || !correctBracket.open ? null : (
                <button
                  onClick={() => {
                    setGroupCode("");
                    setIsCGOpen(!isOpen);
                  }}
                >
                  Create New Group
                </button>
              )}
              <h1>Global Top 25</h1>
              <h5>
                Total Brackets:{" "}
                {correctBracket ? correctBracket.bracketCount : 0}
              </h5>
              <h6>
                Perfect Brackets:{" "}
                {correctBracket ? correctBracket.numPerfect : 0}
              </h6>
              <div className="inner-card">
                <table>
                  <thead>
                    <th>#</th>
                    <th>Name</th>
                    <th>Owner</th>
                    <th>Score</th>
                    <th>Champion</th>
                  </thead>
                  <tbody>
                    {correctBracket
                      ? correctBracket.leaderboard.map((leader, index) => (
                          <tr key={index}>
                            <td>{leader.rank}</td>
                            <td
                              style={{
                                color: "rgb(131, 156, 214)",
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                              onClick={() => {
                                setThisBracket(
                                  new Bracket(
                                    "",
                                    "",
                                    leader.picks,
                                    leader.champ,
                                    leader.name,
                                    leader.owner,
                                    leader.group,
                                    leader.score
                                  )
                                );
                                console.log(leader);
                                setEditing(true);
                              }}
                            >
                              {leader.name}
                            </td>
                            <td>{leader.owner}</td>
                            <td>{leader.score}</td>
                            <td>{leader.champ}</td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
              {correctBracket.open ? null : (
                <div>
                  <h1>Bracket Stats</h1>
                  <h5>Most Popular Champion</h5>
                  <Chart
                    chartType="PieChart"
                    data={correctBracket.sortableChamp}
                    options={{
                      pieSliceText: "label",
                      backgroundColor: "#181D35",
                      chartArea: {
                        width: "98%",
                        top: 8,
                        height: "200px",
                        bottom: 8,
                      },
                      legend: { textStyle: { color: "white" } },
                    }}
                  />
                  <h5>Most Popular First Round Upset Picks</h5>
                  {correctBracket.sortableFRU.map((upset) => {
                    const percent = (
                      (upset[1] / correctBracket.bracketCount) *
                      100
                    ).toFixed(1);
                    return (
                      <div className="upsetContainer">
                        <div
                          className={`upsetPick ${
                            percent > 50 ? "upsetWinner" : "upsetLoser"
                          }`}
                          style={{ flex: upset[1] }}
                        >
                          <h5>{`${percent}% ${upset[0]}`}</h5>
                        </div>
                        <div
                          className={`upsetPick ${
                            percent < 50 ? "upsetWinner" : "upsetLoser"
                          }`}
                          style={{
                            flex: correctBracket.bracketCount - upset[1],
                          }}
                        >
                          <h5>{`${100 - percent}% ${upset[2]}`}</h5>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              <div style={{ paddingTop: "58px" }} />
              <Footer />
            </div>
            {selectedGroup !== "" && !editing ? (
              <div className="curtain" onClick={() => setSelectedGroup("")}>
                <div className="groupContainer">
                  <GroupView
                    group={myGroups[selectedGroup]}
                    setThisBracket={setThisBracket}
                    setEditing={setEditing}
                    setSelectedGroup={setSelectedGroup}
                  />
                </div>
              </div>
            ) : null}
            {isOpen && (
              <Popup
                content={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <b>Enter group code</b>
                    <p>
                      To join an existing group and compete against friends,
                      enter the code for the group here. You can create a new
                      group from the main page.
                    </p>
                    {findError !== "" ? (
                      <p style={{ color: "red" }}>{findError}</p>
                    ) : null}
                    <input
                      type="text"
                      placeholder="Group Code"
                      value={groupCode}
                      onChange={(e) =>
                        setGroupCode(e.target.value.toUpperCase().trim())
                      }
                    />
                    <button
                      onClick={() => {
                        console.log("OK..?");
                        searchForGroup();
                      }}
                      disabled={groupCode == "" || searchingForGroup}
                    >
                      {searchingForGroup ? "Searching.." : "Join Group"}
                    </button>
                  </div>
                }
                handleClose={() => setIsOpen(!isOpen)}
              />
            )}
            {isCGOpen && (
              <Popup
                content={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <b>Create a Group</b>
                    <p>
                      Enter a name for your new group. You can't change this
                      name later.{" "}
                      <strong>
                        If you're trying to join an existing group, click "Join
                        Group" the main page.
                      </strong>
                    </p>
                    <p style={{ fontWeight: "bold", color: "black" }}>
                      Choose a name that fits your group! Something like
                      "Sidewinder VA Coaches"
                    </p>
                    <input
                      type="text"
                      placeholder="Group Name"
                      value={groupName}
                      onChange={(e) => setGroupName(e.target.value)}
                    />
                    <button
                      onClick={() => {
                        createGroup();
                      }}
                      disabled={groupName === "" || searchingForGroup}
                    >
                      {groupName === ""
                        ? "Enter a group name"
                        : searchingForGroup
                        ? "Creating.."
                        : "Create Group"}
                    </button>
                  </div>
                }
                handleClose={() => setIsCGOpen(!isCGOpen)}
              />
            )}
          </div>
        )
      ) : (
        <div style={{ textAlign: "center", color: "white" }}>
          <h1>Loading...</h1>
        </div>
      )}
    </div>
  );
}
export default Dashboard;
